<template>
  <div>
    <fieldset style="width:97%">
      <legend>搜索信息</legend>
      <el-row :gutter="20">
        <el-col :span="5">
          <el-date-picker
              v-model="form.date"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              type="daterange"
              style="width: 100%"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </el-col>
        <el-col :span="4">
          <el-select v-model="form.dressId" placeholder="选择礼服师" clearable>
            <el-option
                v-for="item in dressIdArray"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-button type="primary" @click="search" size="medium">搜索</el-button>
        </el-col>
      </el-row>
    </fieldset>
    <el-row :gutter="20">
      <p class="title">{{ form.dateString }}营收收款项目分析</p>
      <el-col :span="12">
        <div id="incomeView"/>
      </el-col>
      <el-col :span="11" :offset="1">
        <el-table
            :data="incomeData"
            show-summary
            row-key="projectName"
            :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
            style="width: 80%">
          <el-table-column prop="projectName" label="项目名称"/>
          <el-table-column prop="sumMoney" label="金额"/>
        </el-table>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <p class="title">{{ form.dateString }}非营收收款项目分析</p>
      <el-col :span="12">
        <div id="notIncomeView"/>
      </el-col>
      <el-col :span="11" :offset="1">
        <el-table
            :data="notIncomeData"
            row-key="projectName"
            show-summary
            :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
            style="width: 80%">
          <el-table-column prop="projectName" label="项目名称"/>
          <el-table-column prop="sumMoney" label="金额"/>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {Pie} from '@antv/g2plot';

export default {
  name: "proceeds-analysis",
  data() {
    return {
      form:{
        date:this.$dateUtils.getMonthStartEndDayStrArray(),
        dateString: this.$dateUtils.getMonthStartEndDayStr(),
        // shopId:"",
        dressId:"",
        tenantCrop:localStorage.getItem("tenantCrop"),
      },

      incomeData:[],
      incomeTableData:[],
      incomeView:{},
      notIncomeData:[],
      notIncomeTableData:[],
      notIncomeView:{},

      shopIdArray:[],
      dressIdArray:[],

    }
  },
  created() {

    this.queryShopIdArray();
    this.queryDressIdArray();
  },
  mounted() {
    this.initIncomeView();
    this.initNotIncomeView();
    this.queryInCome();
  },
  methods:{
    search(){
      this.form.dateString=this.$dateUtils.stringArrayToString(this.form.date)
      this.queryInCome();
    },
    queryInCome(){
      this.$axios({
        method: 'get',
        url: '/shopReports/queryProceedsAnalysis',
        params: {
          date:this.form.dateString,
          dressId:this.form.dressId,
          shopId:this.form.shopId,
          tenantCrop:this.form.tenantCrop
        },
      }).then(response => {
        this.incomeData = response.data.data[0];
        this.notIncomeData = response.data.data[1];
        this.incomeView.changeData(this.incomeData);
        this.notIncomeView.changeData(this.notIncomeData);
      });
    },
    //查询店铺
    queryShopIdArray() {
      this.$selectUtils.shopIdsIsValid().then(response => {
        this.shopIdArray = JSON.parse(response.data.data)
      })
    },
    //查询礼服师
    queryDressIdArray() {
      this.$selectUtils.queryDressIds().then(response => {
        this.dressIdArray = JSON.parse(response.data.data);
      })
    },

    initIncomeView() {
      this.incomeView = new Pie('incomeView', {
        appendPadding: 1,
        data: this.incomeData,
        mate: {},
        angleField: 'sumMoney',
        colorField: 'projectName',
        radius: 0.8,
        label: {
          type: 'outer',
          content: '{name} {percentage}',
        },
        interactions: [{type: 'pie-legend-active'}, {type: 'element-active'}],
      })
      this.incomeView.render();
    },

    initNotIncomeView() {
      this.notIncomeView = new Pie('notIncomeView', {
        appendPadding: 1,
        data: this.notIncomeData,
        mate: {},
        angleField: 'sumMoney',
        colorField: 'projectName',
        radius: 0.8,
        label: {
          type: 'outer',
          content: '{name} {percentage}',
        },
        interactions: [{type: 'pie-legend-active'}, {type: 'element-active'}],
      })
      this.notIncomeView.render();
    },
    childrenRol({row, column, rowIndex, columnIndex}) {
      return "color: #4c8daa;";
    },
  },
}
</script>

<style scoped>
.title {
  margin: 2% 2%;
  font-size: 30px;
}
/deep/ .el-table__row--level-1 {
  color: #409EFF;
}
</style>